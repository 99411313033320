import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import logo from "../assets/logo.png";

export default function PageLoader() {
  return (
    <div className="flex items-center justify-center h-screen w-screen absolute bg-white">
      {/* <img src={logo} className="" style={{ width: 250 }} alt="Logo" /> */}
      <PulseLoader color={"#000"} loading={true} size={20} />
    </div>
  );
}
