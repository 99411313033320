import React, { useState, useEffect } from "react";
import "./App.css";

import Select from "react-select";

import CurrencyFlag from "react-currency-flags";
import ZimFlag from "./assets/zimbabwe-flag-icon.png";
import logo from "./assets/logo.png";
import chessPiece from "./assets/chesspiece.png";
import { IoRepeatOutline } from "react-icons/io5";

import CurrencyInput from "react-currency-input-field";

import PulseLoader from "react-spinners/PulseLoader";

import PageLoader from "./components/PageLoader";

import gbpIcon from "./assets/gbp.svg"
import eurIcon from "./assets/eur.svg"
import zarIcon from "./assets/zar.svg"

// custom hooks
import { useEffectOnce } from "./hooks/useEffectOnce";
import { useInterval } from "./hooks/useInterval";
import { usePersistedState } from "./hooks/usePersistedState";

import { DateTime, Interval } from "luxon";

// Charts
// @ts-ignore
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import moment from "moment";
const priceData: any = [
  [1589016541000, 800],
  [1589020140000, 750],
  [1589023741000, 700],
];

const options = { style: "currency", currency: "USD" };
const numberFormat: any = new Intl.NumberFormat("en-US", options);

const SELECT_VALUE_KEY = "MySelectValue";

function App() {
  // process.env.REACT_APP_NOT_SECRET_CODE;
  const [currencies, setCurrencies]: any = usePersistedState("afd-currencies", [], true);
  const [options, setOptions]: any = useState(null);

  const [rates, setRates]: any = usePersistedState("afd-rates", [], true);
  // const [timestamp, setTimestamp]: any = useState(null);
  const [ratesTimestamp, setRatesTimestamp]: any = usePersistedState("afd-rates-last-updated", null);
  const [currenciesTimestamp, setCurrenciesTimestamp]: any = usePersistedState("afd-currencies-last-updated", null);

  // const [lastUpdat, setData] = usePersistedState("afd-last-updated", {}, true);

  let [loading, setLoading] = useState(false);

  useEffectOnce(() => {
    if (ratesTimestamp && currenciesTimestamp) {
      const diff = Interval.fromDateTimes(DateTime.fromHTTP(currenciesTimestamp), DateTime.now());
      const diffHours = diff.length("hours");
     
    }

    // console.log("currencyList", currencyList);

    fetchRates();
    // console.log("useEffectOnce Ran");
  });




  function fetchRates() {
    setLoading(true);
    var url = `${process.env.REACT_APP_XE_BASE_URL}convert_from?from=USD&to=*&amount=1&obsolete=false&inverse=true&crypto=false`,
      credentials = btoa(`${process.env.REACT_APP_XE_USERNAME}:${process.env.REACT_APP_XE_PASSWORD}`);

    fetch(url, {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((result) => {
        if (result.status !== 200) {
          throw new Error("Bad Server Response");
        }
        return result.json();
      })
      .then((response) => {
        let responseRates = response.to;
        // const rtgsRate = 650;
        responseRates.push({ quotecurrency: "RTGS", mid: rtgsRate, inverse: 1 / rtgsRate });
        setRates(responseRates);
        const t = DateTime.fromISO(response.timestamp);
        // console.log(t.setLocale("en").toHTTP());
        // console.log(t.setLocale("en").toLocaleString(DateTime.DATETIME_FULL));

        // setRatesTimestamp(t.setLocale("en").toLocaleString(DateTime.DATETIME_FULL));
        setRatesTimestamp(t.setLocale("en").toHTTP());
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }



  const [rateData, setRateData]: any = useState(null);

  const calculateRate = () => {
    const from = rates.find((e: any) => e.quotecurrency === selectedFromCurrency.value);
    const to = rates.find((e: any) => e.quotecurrency === selectedToCurrency.value);

    if (to?.mid !== undefined && to?.inverse !== undefined) {
      const mid = amount * (Number(to.mid) / Number(from.mid));
      const inverse = Number(to.inverse) / Number(from.inverse);
      setRateData({ mid, inverse });
    }
  };

  const [selectedFromCurrency, setSelectedFromCurrency]: any = useState(null);
  const [selectedToCurrency, setSelectedToCurrency]: any = useState(null);
  const [currencySymbol, setCurrencySymbol]: any = useState(null);
  const [amount, setAmount]: any = useState(null);
  const [rtgsRate, setRTGSRate]: any = useState(null);

  const [customPercentage, setCustomPercentage]: any = useState(null);

  const [currencyList, setCurrencyList]: any = useState([]);
  // const [currencyList, setCurrencyList]: any = usePersistedState("afd-currency-list", [], true);
  const handleCurrencyListChange = (e: any) => {
    localStorage.setItem(SELECT_VALUE_KEY, JSON.stringify(e));
    setCurrencyList(e);
  };


  const calculatePercentage = (type: string, percentage: number, number: any) => {
    const per = (number / 100) * percentage;
    // convert number from string to a number
    number = Number(number);
    const result = type === "offer" ? number + per : number - per;
    return Number(result).toFixed(3);
  };

  const getRate = (currency: any) => {
    return Number(rates.find((e: any) => e.quotecurrency === currency).mid) < 1
      ? Number(rates.find((e: any) => e.quotecurrency === currency).inverse).toFixed(4)
      : Number(rates.find((e: any) => e.quotecurrency === currency).mid).toFixed(4);
  };

  const calcPerc = (percentage:number,number:any,above:boolean) => {
    const perc = above ? number + number / 100 * percentage : number - number / 100 * percentage;
    return Number(perc).toFixed(4)
  }

  return loading ? (
    <PageLoader />
  ) : (
    <>
      <div className="flex justify-center">
        <img src={logo} className="w-96" alt="Access Finance" />
      </div>
      
       {rates.length > 0 ? <div className="flex flex-row gap-4 m-8">
          <div className=" flex-1 mt-2 flex flex-col items-center bg-white rounded-md py-10 px-12 shadow-lg">
            <img src={zarIcon}  style={{width:80}}/>
            <p className="text-8xl mt-2 font-bold p-2" style={{ color: "#1b1464" }}>
              ZAR
            </p>
            <div className="flex flex-row justify-between items-center gap-4 my-8">
              <div className="flex flex-row justify-center items-center gap-2">
                <span className="font-bold">WE BUY</span>
                <span className="p-2 rounded-md text-3xl font-bold" style={{ backgroundColor: "#f2f4ff", color: "#9d9ebc" }}>
                {calcPerc(3,Number(getRate("ZAR")),true)}
                </span>
              </div>
              <div className="flex flex-row justify-center items-center gap-2">
                <span className="font-bold">WE SELL</span>
                <span className="p-2 rounded-md text-3xl font-bold" style={{ backgroundColor: "#f2f4ff", color: "#9d9ebc" }}>
                {calcPerc(3,Number(getRate("ZAR")),false)}
                </span>
              </div>
            </div>
          </div>
          <div className=" flex-1 mt-2 flex flex-col items-center bg-white rounded-md py-10 px-12 shadow-lg">
            <img src={gbpIcon}  style={{width:80}}/>
            <p className="text-8xl mt-2 font-bold p-2" style={{ color: "#1b1464" }}>
              GBP
            </p>
            <div className="flex flex-row justify-between items-center gap-4 my-8">
              <div className="flex flex-row justify-center items-center gap-2">
                <span className="font-bold">WE BUY</span>
                <span className="p-2 rounded-md text-3xl font-bold" style={{ backgroundColor: "#f2f4ff", color: "#9d9ebc" }}>
                {calcPerc(5,Number(getRate("GBP")),false)}
                </span>
              </div>
              <div className="flex flex-row justify-center items-center gap-2">
                <span className="font-bold">WE SELL</span>
                <span className="p-2 rounded-md text-3xl font-bold" style={{ backgroundColor: "#f2f4ff", color: "#9d9ebc" }}>
                {calcPerc(4,Number(getRate("GBP")),true)}
                </span>
              </div>
            </div>
          </div>
          <div className=" flex-1 mt-2 flex flex-col items-center bg-white rounded-md py-10 px-12 shadow-lg">
            <img src={eurIcon}  style={{width:80}}/>
            <p className="text-8xl mt-2 font-bold p-2" style={{ color: "#1b1464" }}>
              EUR
            </p>
            <div className="flex flex-row justify-between items-center gap-4 my-8">
              <div className="flex flex-row justify-center items-center gap-2">
                <span className="font-bold">WE BUY</span>
                <span className="p-2 rounded-md text-3xl font-bold" style={{ backgroundColor: "#f2f4ff", color: "#9d9ebc" }}>
                {calcPerc(5,Number(getRate("EUR")),false)}
                </span>
              </div>
              <div className="flex flex-row justify-center items-center gap-2">
                <span className="font-bold">WE SELL</span>
                <span className="p-2 rounded-md text-3xl font-bold" style={{ backgroundColor: "#f2f4ff", color: "#9d9ebc" }}>
                {calcPerc(5,Number(getRate("EUR")),true)}
                </span>
              </div>
            </div>
          </div>
       </div> : null}
     
      <footer className="relative pt-8 pb-6 mt-2">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full px-4 mx-auto flex flex-col justify-center items-center">
              <img src={chessPiece} className="w-24" alt="Access Finance" />
              <div className="text-sm text-blueGray-500 font-semibold py-1">Powered By Access Finance</div>
              <p
                className="my-2 p-2 px-8 rounded-md text-center font-bold"
                style={{ backgroundColor: "#fff8ee", color: "#eab489" }}
              >
                Last updated {ratesTimestamp}
              </p>
              <p className="text-blueGray-500 font-bold">PLEASE NOTE THESE ARE INDICATIVE RATES</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
